import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivityInterface, ProjectInterface, UserInterface, RubroInterface } from '../models/models'
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserData } from '../@core/data/users';

@Injectable({
	providedIn: 'root'
})
export class DataApiService {

	constructor(private angularFirestore: AngularFirestore) { }

	// ////////////////////////////////////////////////////////// //
	//    Proyectos de un usuario especifico.
	getAllProjects(uid: string) {
		let projects: Observable<ProjectInterface[]>;
		projects = this.angularFirestore.collection('projects', ref => ref.where('uid_user', '==', uid)).valueChanges();
		return projects;
	}
	getProject({ uid, id }: { uid?: string; id: string; }): Observable<any> {
		let projects: Observable<ProjectInterface[]>;

		if (uid) {
			projects = this.angularFirestore.collection('projects',
				ref => ref.where('uid_user', '==', uid).where('id', '==', id)
			).valueChanges();
			return projects;
		} else {
			//console.log(id);			
			projects = this.angularFirestore.collection('projects',
				ref => ref.where('id', '==', id)
			).valueChanges();
			return projects;
		}
	}
	// ////////////////////////////////////////////////////////// //
	//    Canvas de un proyecto de un usuario especifico.
	setCanvas(id_projects: string, canvas: Object) {
		return this.angularFirestore.collection('projects').doc(id_projects).update(canvas);
	}
	updateProfile(id_projects: string, project: Object) {
		return this.angularFirestore.collection('projects').doc(id_projects).update(project);
	}
	// ////////////////////////////////////////////////////////// //
	//    Rubros del presupuesto de un usuario especifico.
	getAllRubros(id_Projects: string, category?: string) {
		let rubros: Observable<RubroInterface[]>;
		let ref = this.angularFirestore.collection('budgets').doc(id_Projects);
		if (category) {
			rubros = ref.collection('rubros', ref => ref.where('category_category_id', '==', category)).valueChanges();
		} else {
			rubros = ref.collection('rubros').valueChanges();
		}
		return rubros;
	}
	deleteRubro(id_Projects: string, id_rubro: string) {
		return this.angularFirestore.collection('budgets').doc(id_Projects).collection('rubros').doc(id_rubro).delete();
	}
	// ////////////////////////////////////////////////////////// //
	//    Actividades del cornograma de un usuario especifico.
	getAllitems(id_Projects: string, category?: string) {
		let activities: Observable<RubroInterface[]>;
		let ref = this.angularFirestore.collection('chronograms').doc(id_Projects);
		if (category) {
			activities = ref.collection('activities', ref => ref.where('category_category_id', '==', category)).valueChanges();			
		} else {
			activities = ref.collection('activities').valueChanges();			
		}
		return activities;
	}		
	deleteItem(id_Projects: string, id_item: string) {
		return this.angularFirestore.collection('chronograms').doc(id_Projects).collection('activities').doc(id_item).delete();
	}
	// ////////////////////////////////////////////////////////// //
	//    Archivos multimedia de un proyecto de un usuario especifico.
	getAllMediaProjects(id_Projects: string){
		let urls: Observable<any[]>;
		urls = this.angularFirestore.collection('projects').doc(id_Projects).collection('media').valueChanges();
		return urls;
	}
	deleteMediaProjects(id_Projects: string, id_item: string){
		return this.angularFirestore.collection('projects').doc(id_Projects).collection('media').doc(id_item).delete();
	}
	setUrlMediaProject(id_projects: string, url: any) {
		return this.angularFirestore.collection('projects').doc(id_projects).collection('media').doc(url.id).set(url);
	}
	
	/////////////////////////////////////////////////////////////////////////////
	getUser(uid: string){
		let users: Observable<UserInterface[]>;
		users = this.angularFirestore.collection('users', ref => ref.where('uid', '==', uid)).valueChanges();
		return users;
	}
	public get allUser() :  Observable<UserInterface[]> {
		let users: Observable<UserInterface[]>;
		users = this.angularFirestore.collection('users', ref => ref.where('roles.entrepreneur', '==', true)).valueChanges();
		return users;
	}
	public get allProjects(): Observable<ProjectInterface[]> {	
		let projects: Observable<ProjectInterface[]>;
		projects = this.angularFirestore.collection('projects').valueChanges();
		return projects;
	}
	/////////////////////////////////////////////////////////////////////////////
	
}
